import React, { useState } from 'react';
import { Box, Typography, TextField, Button, IconButton, Dialog, DialogContent, CircularProgress } from '@mui/material'
import axios from 'axios';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';


function getRandomInt() {
    return Math.floor(Math.random() * 100000);
}


const TextToAudioDialog = ({ open, setOpen, id }) => {
  const [text, setText] = useState('');
  const [audioSrc, setAudioSrc] = useState('');
  const [loading, setLoading] = useState(false);
  const [audioSrcKey, setAudioSrcKey] = useState(1)

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleClose = () => {
    setText(''); // Clear the text when closing the dialog
    setOpen(false);
    setAudioSrc('');
  };


  const handleGenerateAudio  = async () => {
    try {
        setLoading(true);
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/voices/${id}/audio`, 
        { text: text }, 
        { responseType: 'blob',
          withCredentials: true, // Include cookies in the request
        });

        if (response.status === 200) {
          const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);
          audio.play();
          setAudioSrc(audioUrl)
          setAudioSrcKey(getRandomInt())
          setText('')
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false); // Set loading state to false after the response
      }
    };

  return (
    <Dialog open={open} onClose={handleClose}>
        <DialogContent>
            <Box
                display="flex"
                flexDirection="column"
            >
                <Typography variant="h2">Generate Audio</Typography>
                <TextField
                    placeholder="Enter text to convert to audio"
                    multiline
                    rows={4}
                    value={text}
                    fullWidth 
                    onChange={handleTextChange}
                    sx = {{
                        width: 400,
                        margin: "10px 0px 0px 0px"
                    }}
                />
                {audioSrc && 
                    <Box
                        margin="10px 0px 0px 0px"
                        display="flex"
                        justifyContent="center"
                    >
                        {audioSrc && (
                            <audio controls key={audioSrcKey}>
                                <source src={audioSrc} type="audio/mpeg" key={audioSrcKey}/>
                                Your browser does not support the audio element.
                            </audio>
                        )}
                    </Box>
                }
                <Box
                    display="flex"
                    justifyContent="space-between"
                    margin="10px 0px 0px 0px"
                >
                    <Button 
                        onClick={handleGenerateAudio}
                        variant="contained" 
                        color="primary" 
                        disabled={text === "" || loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Generate Audio'}
                    </Button>
                    <Button 
                        onClick={handleClose}
                        variant="contained" 
                        color="primary" 
                    >
                        Close
                    </Button>
                </Box>
            </Box>
        </DialogContent>
    </Dialog>
  );
}



const AudioVoicePlay = ({id}) => {
  const [open, setOpen] = useState(false);


  const openDialog = () => {
    setOpen(true);
  };


  return (
    <Box>
      <IconButton onClick={openDialog}><PlayCircleOutlinedIcon /></IconButton>
      <TextToAudioDialog
        open={open}
        setOpen={setOpen}
        id={id}
      />
    </Box>
  );
}

export default AudioVoicePlay;