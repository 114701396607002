import { createTheme } from "@mui/material/styles";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import axios from 'axios';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { themeSettings } from "./theme";

import NavBar from "./scenes/NavBar";
import Videos from "./scenes/Videos";
import VideoDetails from "./scenes/VideoDetails";
import Languages from "./scenes/Languages";
import Voices from "./scenes/Voices";
import User from "./scenes/User";
import SignIn from "./scenes/SignIn";

function App() {
  const theme = createTheme(themeSettings());
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    async function checkLoginStatus() {
      try {
        // Make a request to the check_login route to verify login status
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/check_login`, {
          withCredentials: true,
        });
        if (response.status === 200 && response.data.logged_in) {
          setIsLoggedIn(true);
          setIsAdmin(response.data.is_admin)
        }
      } catch (error) {
        setIsLoggedIn(false);
      }
    }

    checkLoginStatus();
  }, []);

  if (isLoggedIn === null) {
    // Display a loading indicator while checking login status
    return <div></div>;
  }


  return (
    <div className="app">
      <ThemeProvider theme={theme}>
          <BrowserRouter>
              <CssBaseline />   
                <main className="content">
                  {!isLoggedIn ?
                  <Routes>
                    <Route path="*" element={<SignIn />} />
                  </Routes>
                  : 
                  isAdmin ? 
                  <>
                  <NavBar isAdmin={isAdmin}/>
                  <Routes>
                      <Route path="/" element={<Videos />} />
                      <Route path="/:id" element={<VideoDetails /> } />
                      <Route path="/voices" element={<Voices /> } />
                      <Route path="/languages" element={<Languages /> } />
                      <Route path="/user" element={<User /> } />
                  </Routes>
                </>:
                <>
                  <NavBar isAdmin={isAdmin}/>
                  <Routes>
                      <Route path="/" element={<Videos />} />
                      <Route path="/:id" element={<VideoDetails /> } />
                  </Routes>
                </>
                  }
                </main>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
