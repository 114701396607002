import React, { useState } from 'react'
import { Box, Typography, useMediaQuery, Button } from '@mui/material'
import { useTheme } from "@emotion/react";
import { Link } from 'react-router-dom';
import axios from 'axios';

const LinkItem = ({name, to, selected, setSelected}) => {
    const theme= useTheme();
    return (
        <Link
            to={to}
            style={{
                textDecoration: "none",
                }}
         > 
            <Typography 
                variant="h4"
                color={selected === name ? theme.palette.secondary[100]: theme.palette.secondary[500]}
                onClick={() => setSelected(name)}
                sx={{
                    '&:hover': {
                    color: theme.palette.secondary[100]
                    },
                }}
            > 
                {name}
            </Typography>
        </Link>
    )
}


const NavBar = ({isAdmin}) => {
  const [selected, setSelected] = useState("Dashboard");


  const handleLogout = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/logout`, {},
    {
        withCredentials: true, // Include cookies in the request
    })
        .then(response => console.log("logout!"))
        .catch(error => {
            console.error('There was an error!', error);
        });
    window.location.href = '/';
  };


  return (
    <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        margin="20px 25px 25px 50px"
    >
        <Box
            display="flex"
            gap="25px"
        >
            {isAdmin ?
            <>
                <LinkItem name={"Videos"} to={"/"} selected={selected} setSelected={setSelected} />  
                <LinkItem name={"Voices"} to={"/voices"} selected={selected} setSelected={setSelected} /> 
                <LinkItem name={"Languages"} to={"/languages"} selected={selected} setSelected={setSelected} />
                <LinkItem name={"User"} to={"/user"} selected={selected} setSelected={setSelected} />  
            </> :
                <LinkItem name={"Videos"} to={"/"} selected={selected} setSelected={setSelected} />  
            }
        </Box>
                {/* Add Logout Button */}
                
        <Button variant="outlined" onClick={handleLogout}>Logout</Button>
      
    </Box>
  )
}

export default NavBar
