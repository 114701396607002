import { Box, Typography, Button, TextareaAutosize, IconButton, CircularProgress } from '@mui/material'
import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import RefreshIcon from '@mui/icons-material/Refresh';
import LanguageSelectionTitle from '../languageSelectionTitle';
import LanguageSelectionDescription from '../languageSelectionDescription';

function getRandomInt() {
  return Math.floor(Math.random() * 100000);
}

function DescriptionForm({id, setOpen}) {
  const [text, setText] = useState(undefined);
  const [update, setUpdate] = useState(0)


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/${id}/details`,
    {
      withCredentials: true, // Include cookies in the request
    }
    )
    .then((response) => {
        {response.data.youtube_description ===null ? setText(undefined) : setText(response.data.youtube_description)}
    })
    .catch((error) => {
        console.error(error);
    });
  }, [])

  const handleChange = (event) => {
    setText(event.target.value)
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/video/${id}/update`, 
    {"youtube_description": event.target.value},
    {
      withCredentials: true, // Include cookies in the request
    }
    )
    .then((response) => {
        setUpdate(getRandomInt())
    })
    .catch((error) => {
        console.error(error);
    });
  }

  const handleSubmit = () => {
    // You can perform any action you want with the 'text' data here.
    // For example, you can send it to an API or update a state in your parent component.
    setOpen(true)
  };

  return (
    <Box>
      <TextareaAutosize
        minRows={4}
        maxRows={10}
        value={text}
        onChange={handleChange}
        style={{ width: '100%' }}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Translate
      </Button>
    </Box>
  );
}

function TitleForm({id, setOpen}) {
    const [text, setText] = useState(undefined);
    const [update, setUpdate] = useState(0)


    useEffect(() => {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/${id}/details`,
      {
        withCredentials: true, // Include cookies in the request
      }
      )
      .then((response) => {
        {response.data.youtube_title === null ? setText(undefined) : setText(response.data.youtube_title)}
      })
      .catch((error) => {
          console.error(error);
      });
    }, [])

    const handleChange = (event) => {
      setText(event.target.value)
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/video/${id}/update`, 
      {"youtube_title": event.target.value},
      {
        withCredentials: true, // Include cookies in the request
      }
      )
      .then((response) => {
          setUpdate(getRandomInt())
      })
      .catch((error) => {
          console.error(error);
      });
    }
  
  
    const handleSubmit = () => {
      // You can perform any action you want with the 'text' data here.
      // For example, you can send it to an API or update a state in your parent component.
      setOpen(true)
    };
  
    return (
      <Box>
        <TextareaAutosize
          minRows={4}
          maxRows={10}
          value={text}
          onChange={handleChange}
          style={{ width: '100%' }}
        />
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Translate
        </Button>
      </Box>
    );
  }

const gridTemplate = `
"a b"
"c d"
"c d"
"c d"
"c d"
`

const RegenerateSentenceIcon = ({video_id, language_id, translation_id, setUpdate}) => {
    const [loading, setLoading] = useState(false);
  
    const handleSentenseRegeneration = () => {
      setLoading(true)
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/translations/${translation_id}/regenerate`, 
      {"video_id":video_id, "language_id":language_id},
      {
        withCredentials: true, // Include cookies in the request
      }
      )
      .then(response => {setUpdate(getRandomInt());setLoading(false)})
      .catch(error => {
          console.error('There was an error!', error);
      });
    };
  
    return (
      <IconButton
        onClick={handleSentenseRegeneration}
      >
        {loading ? 
          <CircularProgress size={24} /> 
          : 
        <RefreshIcon style={{
          height:"25px",
          width:"25px",
        }}/>
      }
      </IconButton>
    )
  }

const Table = ({id, setUpdate, update, type}) => {
    const [tableData, setTableData] = useState([]);

    const columns = useMemo(
        () => [
          {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 5,
            enableEditing: false
          },
          {
            accessorKey: 'language_name', //access nested data with dot notation
            header: 'Language',
            enableEditing: false
          },
          {
            accessorKey: 'sentence', //access nested data with dot notation
            header: 'Sentence',
            enableEditing: false
          },
        ],
        [],
      );

      useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/${id}/${type}translations`,
        {
          withCredentials: true, // Include cookies in the request
        }
        )
        .then((response) => {
            setTableData(response.data)
        })
        .catch((error) => {
            console.error(error);
        });
    }, [update]);


      const handleSaveCell = (cell, value) => {
        //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
        //tableData[cell.row.index][cell.column.id] = value;
        //send/receive api updates here

        let language_info = {}
        language_info[cell.column.id] = value

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/languages/${cell.row.original.id}/update`, 
            language_info,
            {
              withCredentials: true, // Include cookies in the request
            }
            )
            .then(response => setUpdate(getRandomInt()))
            .catch(error => {
                console.error('There was an error!', error);
            });
      };
    
      return (
        <MaterialReactTable
          columns={columns}
          data={tableData}
          editingMode="cell"
          enableEditing
          muiTablePaperProps={{
            elevation: 3, //change the mui box shadow
          }}
          muiTableHeadCellProps = {{
            //no useTheme hook needed, just use the `sx` prop with the theme callback
            sx: (theme) => ({
              backgroundColor: theme.palette.grey[300],
            }),
          }}
          muiTableBodyCellEditTextFieldProps={({ cell }) => ({
            //onBlur is more efficient, but could use onChange instead
            onBlur: (event) => {
              handleSaveCell(cell, event.target.value);
            },
          })}
          renderBottomToolbarCustomActions={() => (
            <Typography sx={{ fontStyle: 'italic', p: '0 1rem' }} component={'span'} variant="body2">
              Double-Click a Cell to Edit
            </Typography>
          )}
          renderRowActions={({ cell, row, table }) => 
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
            <RegenerateSentenceIcon  video_id={id} language_id={row.original.language_id} translation_id={row.original.translation_id} setUpdate={setUpdate}/>     
          </Box>
        }
        />
        )
}

const TitleDescription = ({id}) => {
    const [openTitleSelection, setOpenTitleSelection] = useState(false);
    const [openDescriptionSelection, setOpenDescriptionSelection] = useState(false);
    const [update, setUpdate] = useState(0);

    return (
        <Box
            display="grid"
            gap="1.5rem"
            sx={{
            gridTemplateColumns: 'repeat(2, minmax(220px, 1fr))', // Three columns now
            gridTemplateAreas: gridTemplate,
            }}
        >
        <Box gridArea="a">
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr',
              gridTemplateRows: 'auto 1fr', // Two rows: header and content
              gap: '0.5rem',
            }}
          >
            <Typography component={'span'} variant="h2">Title</Typography> {/* Header */}
            <TitleForm id={id} setOpen={setOpenTitleSelection}/>
          </Box>
        </Box>
        <Box gridArea="b">
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr',
              gridTemplateRows: 'auto 1fr', // Two rows: header and content
              gap: '0.5rem',
            }}
          >
            <Typography component={'span'} variant="h2">Description</Typography> {/* Header */}
            <DescriptionForm id={id} setOpen={setOpenDescriptionSelection}/>
          </Box>
        </Box>
        <Box gridArea="c">
          <LanguageSelectionTitle setUpdate={setUpdate} id={id} open={openTitleSelection} setOpen={setOpenTitleSelection}/>
          <Table id={id} type={"title"} update={update} setUpdate={setUpdate}/>
        </Box>
        <Box gridArea="d">
          <LanguageSelectionDescription setUpdate={setUpdate} id={id} open={openDescriptionSelection} setOpen={setOpenDescriptionSelection}/>
          <Table id={id} type={"description"} update={update} setUpdate={setUpdate}/>
        </Box>
      </Box>
    )
}

export default TitleDescription;