import React, { useEffect, useState } from 'react';
import { Box, Button, Input, Paper,  Select, MenuItem, FormControl, FormHelperText,TextField, FormControlLabel, Radio, RadioGroup, InputLabel, CircularProgress, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined';
import Looks3OutlinedIcon from '@mui/icons-material/Looks3Outlined';
import Looks4OutlinedIcon from '@mui/icons-material/Looks4Outlined';
import ArticleIcon from '@mui/icons-material/Article';
import axios from 'axios';

function getRandomInt() {
  return Math.floor(Math.random() * 100000);
}

const gridTemplate = `
"a b c d"
`

const UploadAudioButton = ({id, youtubeID, audioFile, audioVoice, setUploadResponse, setUpdate, disabled}) => {
  const [loading, setLoading] = useState(false);
  const [audioUploadError, setAudioUploadError] = useState(null);

  const handleUpload = async (e) => {
    e.preventDefault();

    if (audioFile) {
        const formData = new FormData();
        formData.append('audioFile', audioFile);
        formData.append('audioVoice', audioVoice);

        try {
            setLoading(true)
            setAudioUploadError(null)
            let response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/audio_files/${id}/upload`, 
            formData,
            {
              withCredentials: true, // Include cookies in the request
            }
            );
            setUploadResponse(response)
            setUpdate(getRandomInt())
            setLoading(false)
        } catch (error) {
            setAudioUploadError('Error uploading audio file');
        }
    } else if (youtubeID) {

        try {
            setLoading(true)
            setAudioUploadError(null)
            let response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/audio_files/${id}/download`, 
            {"youtube_id":youtubeID, "audio_main_voice":audioVoice},
            {
              withCredentials: true, // Include cookies in the request
            }
            );
            setUploadResponse(response)
            setUpdate(getRandomInt())
            setLoading(false)
        } catch (error) {
            setAudioUploadError('Error. Try again!');
            setLoading(false)
        }
    }
  };

  return (
    <FormControl error={audioUploadError}>
      <Button
        variant="contained"
        component="span"
        startIcon={loading ? <CircularProgress size={24} />: <CloudUploadIcon />}
        style={{ width:"150px" }}
        onClick={handleUpload}
        disabled={loading || disabled}

    >
      Upload Audio
    </Button>
    <FormHelperText>{audioUploadError}</FormHelperText>

  </FormControl>
  )
}

const GenerateTranscriptionButton = ({id, setAudioFile,setAudioVoice,setYoutubeID, setUploadResponse, setUpdate, disabled}) => {
  const [loading, setLoading] = useState(false);
  const [transcriptionError, setTranscriptionError] = useState(null);


  const generateTranscription = async (e) => {
    try {
        setTranscriptionError(null)
        setLoading(true)
        let response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/transcriptions/${id}/generate`, {},
        {
          withCredentials: true, // Include cookies in the request
        }
        );
        setUpdate(getRandomInt())
        setAudioFile(null)
        setAudioVoice(null)
        setYoutubeID(null)
        setUploadResponse(null)
    } catch (error) {
        setTranscriptionError("Error. Try again!")
    } finally {
      setLoading(false)
    }
    }

  return (
    <FormControl error={transcriptionError}>
      <Button
        variant="contained"
        component="span"
        style={{ width:"250px" }}
        onClick={generateTranscription}
        disabled={loading || disabled}
        startIcon={loading ? <CircularProgress size={24} />: <ArticleIcon />}
      >
        Generate Transcription
      </Button>
      <FormHelperText>{transcriptionError}</FormHelperText>
    </FormControl>
  )
}

const FileUpload = ({id, update, setUpdate}) => {
  const [audioFile, setAudioFile] = useState(null);
  const [audioVoice, setAudioVoice] = useState("");
  const [youtubeID, setYoutubeID] = useState(null);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('audio');
  const [dropdownItems, setDropdownItems] = useState([]);
  

  useEffect(() => {
    let response = axios.get(`${process.env.REACT_APP_BACKEND_URL}/voices/active`,
    {
      withCredentials: true, // Include cookies in the request
    }
    )
      .then(response => setDropdownItems(response.data))
  }, []);

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setYoutubeID(null)
    setAudioFile(null)
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setAudioFile(file);
  };

  const handleYoutubeID = (event) => {
    const youtube_url = event.target.value;
    setYoutubeID(youtube_url);
  };

  const handleVoiceChange = (event) => {
    setAudioVoice(event.target.value);
  };

  return (
    <Paper 
      elevation={3} 
      style={{ 
        padding: '20px',
        marginTop: '20px'
      }}
    >
      <Box
        display="grid"
        gap="1.5rem"
        sx = {{
              gridTemplateColumns: "repeat(4, minmax(220px, 1fr))",
                gridTemplateAreas: gridTemplate,
            }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <LooksOneOutlinedIcon fontSize="large" sx={{margin:"20px"}}/>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="audio"
                name="radio-buttons-group"
                onChange={handleRadioChange}
              >
                <FormControlLabel value="audio" control={<Radio />} label="Audio" />
                <FormControlLabel value="video" control={<Radio />} label="Video" />
              </RadioGroup>
            </FormControl>
            {value === "audio" ?
              <Box>
                <Input
                  type="file"
                  accept="audio/*" // Set the allowed file types
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  id="file-input"
                />
                <label htmlFor="file-input">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    style={{ width:"120px" }}
                  >
                    Select File
                  </Button>
                </label>
              </Box>
              :
              <Box>
                <TextField
                  label="YouTube ID"
                  type={"url"}
                  size="small"
                  onChange={handleYoutubeID}
                  value={youtubeID}
                />
              </Box>
              }
          </Box>

        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <LooksTwoOutlinedIcon fontSize="large" sx={{margin:"20px"}}/>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel>Main Voice</InputLabel>
            <Select
              value={audioVoice}
              label="MainVoice"
              onChange={handleVoiceChange}
              disabled={!audioFile && !youtubeID}
              sx = {{

                width: '200px'
              }}
            >
              {dropdownItems.map((item) => <MenuItem key={item["id"]} value={item["id"]}>{item["name"]}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Looks3OutlinedIcon fontSize="large" sx={{margin:"20px"}}/>
          <UploadAudioButton disabled={!audioVoice || (!audioFile && !youtubeID)} id={id} youtubeID={youtubeID} audioFile={audioFile} audioVoice={audioVoice} setUploadResponse={setUploadResponse} setUpdate={setUpdate}/>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Looks4OutlinedIcon fontSize="large" sx={{margin:"20px"}}/>
          <GenerateTranscriptionButton id={id} setAudioFile={setAudioFile} setAudioVoice={setAudioVoice} setYoutubeID={setYoutubeID} setUploadResponse={setUploadResponse} setUpdate={setUpdate} disabled={!uploadResponse}/>
        </Box>
      </Box>
    </Paper>
    
  );
};

export default FileUpload;