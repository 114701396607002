import { Box, Typography, IconButton, Button, Checkbox, Slider, Tooltip, FormControl, InputLabel, Select, MenuItem  } from '@mui/material'
import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import AudioDropzone from '../../components/audioDropzone';
import AudioVoicePlay from '../../components/audioVoicePlay';

const HeaderWithInfo = ({headerName, headerInfo}) => {
  return (
    <Box
      display="flex"
      gap="5px"
    >
      <Typography
        fontSize="0.75rem"
        lineHeight="1.2857142857142856rem"
        fontWeight="700"
      >
        {headerName}
      </Typography>
      <Tooltip 
        title={headerInfo}
        PopperProps={{placement: "top"}}
      >
        <HelpOutlineOutlinedIcon />
      </Tooltip>
    </Box>
  )
}

function getRandomInt() {
    return Math.floor(Math.random() * 100000);
}


const DropdownColumn = ({ label, options, value, onChange }) => {
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <InputLabel>Voice</InputLabel>
      <Select label={label} value={value} onChange={onChange}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};


const Table = ({tableData, setUpdate}) => {

    const columns = useMemo(
        () => [
          {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 5,
            enableEditing: false
          },
          {
            accessorKey: 'name', //normal accessorKey
            header: 'Name',
          },
          {
            accessorKey: 'elevenlabs_id',
            header: 'ElevenLabs Id',
          },
          {
            title: "elevenlabs_model",
            header: 'Model',
            accessorKey: 'elevenlabs_model',
            enableEditing: false,
            Cell: ({ cell }) => (
              <DropdownColumn 
                label="Voice"
                options={[
                  { value: 'eleven_multilingual_v1', label: 'eleven_multilingual_v1' },
                  { value: 'eleven_multilingual_v2', label: 'eleven_multilingual_v2' },
                  // Add more options as needed
                ]} 
                value={cell.row.original.elevenlabs_model}
                onChange={event => {
                  axios.post(`${process.env.REACT_APP_BACKEND_URL}/voices/${cell.row.original.id}/update`, 
                  {elevenlabs_model: event.target.value},
                  {
                    withCredentials: true, // Include cookies in the request
                  }
                  )
                  .then(response => setUpdate(getRandomInt()))
                  .catch(error => {
                      console.error('There was an error!', error);
                  });
                }}
                />
            )
          },
          {
            accessorKey: 'elevenlabs_stability',
            header: 'Stability',
            Header: <HeaderWithInfo headerName={"Stability"} headerInfo={"Increasing stability will make the voice more consistent between re-generations, but it can also make it sounds a bit monotone. On longer text fragments we recommend lowering this value."}/>,
            Cell: ({ cell }) => (
              <Slider
                aria-label="Small steps"
                key={`slider-${cell.row.original.elevenlabs_stability}`}
                defaultValue={cell.row.original.elevenlabs_stability}
                step={0.01}
                marks
                min={0}
                max={1}
                valueLabelDisplay="auto"
                onChangeCommitted={(e, val) => {
                  axios.post(`${process.env.REACT_APP_BACKEND_URL}/voices/${cell.row.original.id}/update`, 
                  {elevenlabs_stability: val},
                  {
                    withCredentials: true, // Include cookies in the request
                  }
                  )
                  .then(response => setUpdate(getRandomInt()))
                  .catch(error => {
                      console.error('There was an error!', error);
                  });
                }
                } />
            )
          },
          {
            accessorKey: 'elevenlabs_similarity_boost',
            header: 'Similarity Boost',
            Header: <HeaderWithInfo headerName={"Similarity Boost"} headerInfo={"High enhancement boosts overall voice clarity and target speaker similarity. Very high values can cause artifacts, so adjusting this setting to find the optimal value is encouraged."}/>,
            Cell: ({ cell }) => (
              <Slider
                aria-label="Small steps"
                key={`slider-${cell.row.original.elevenlabs_similarity_boost}`}
                defaultValue={cell.row.original.elevenlabs_similarity_boost}
                step={0.01}
                marks
                min={0}
                max={1}
                valueLabelDisplay="auto"
                onChangeCommitted={(e, val) => {
                  axios.post(`${process.env.REACT_APP_BACKEND_URL}/voices/${cell.row.original.id}/update`, 
                  {elevenlabs_similarity_boost: val},
                  {
                    withCredentials: true, // Include cookies in the request
                  }
                  )
                  .then(response => setUpdate(getRandomInt()))
                  .catch(error => {
                      console.error('There was an error!', error);
                  });
                }
                } />
            )
          },
          {
            accessorKey: 'elevenlabs_style',
            header: 'Style',
            Header: <HeaderWithInfo headerName={"Style"} headerInfo={"High values are recommended if the style of the speech should be exaggerated compared to the uploaded audio. Higher values can lead to more instability in the generated speech. Setting this to 0.0 will greatly increase generation speed and is the default setting."}/>,
            Cell: ({ cell }) => (
              <Slider
                aria-label="Small steps"
                key={`slider-${cell.row.original.elevenlabs_style}`}
                defaultValue={cell.row.original.elevenlabs_style}
                step={0.01}
                marks
                min={0}
                max={1}
                valueLabelDisplay="auto"
                onChangeCommitted={(e, val) => {
                      axios.post(`${process.env.REACT_APP_BACKEND_URL}/voices/${cell.row.original.id}/update`, 
                      {elevenlabs_style: val},
                      {
                        withCredentials: true, // Include cookies in the request
                      }
                      )
                      .then(response => setUpdate(getRandomInt()))
                      .catch(error => {
                          console.error('There was an error!', error);
                      });
                    }
                } />
            )
          },
          {
            accessorKey: 'elevenlabs_use_speaker_boost',
            header: 'Speaker Boost',
            Header: <HeaderWithInfo headerName={"Speaker Boost"} headerInfo={"Boost the similarity of the synthesized speech and the voice at the cost of some generation speed."}/>,
            Cell: ({ cell }) => (
              <Checkbox 
                checked={cell.row.original.elevenlabs_use_speaker_boost} 
                onClick={() => {
                    axios.post(`${process.env.REACT_APP_BACKEND_URL}/voices/${cell.row.original.id}/update`, 
                    {elevenlabs_use_speaker_boost: !cell.row.original.elevenlabs_use_speaker_boost},
                    {
                      withCredentials: true, // Include cookies in the request
                    }
                    )
                    .then(response => setUpdate(getRandomInt()))
                    .catch(error => {
                        console.error('There was an error!', error);
                    });
                }} />
            )
          },
          {
            title: "Active",
            header: 'Active',
            accessorKey: 'active',
            enableEditing: false,
            Cell: ({ cell }) => (
              <Checkbox 
                checked={cell.row.original.active} 
                onClick={() => {
                    axios.post(`${process.env.REACT_APP_BACKEND_URL}/voices/${cell.row.original.id}/update`, 
                    {active: !cell.row.original.active},
                    {
                      withCredentials: true, // Include cookies in the request
                    }
                    )
                    .then(response => setUpdate(getRandomInt()))
                    .catch(error => {
                        console.error('There was an error!', error);
                    });
                }} />
            )
          },
          {
            title: "FirstAction",
            header: 'Action',
            enableEditing: false,
            Cell: ({ cell }) => (
                <Box
                  display="flex"
                >
                    <AudioVoicePlay id={cell.row.original.id}/>
                    <IconButton
                        color="error"
                        onClick={() => {
                            window.confirm("Löschen?") ?
                            axios.post(`${process.env.REACT_APP_BACKEND_URL}/voices/${cell.row.original.id}/delete`, {},
                            {
                              withCredentials: true, // Include cookies in the request
                            }
                            )
                            .then(response => setUpdate(getRandomInt()))
                            .catch(error => {
                                console.error('There was an error!', error);
                            }):
                            console.log("Error")
                        }}
                    >
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </Box>
            )
          },
        ],
        [],
      );


      const handleSaveCell = (cell, value) => {
        //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
        //tableData[cell.row.index][cell.column.id] = value;
        //send/receive api updates here

        let voice_info = {}
        voice_info[cell.column.id] = value

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/voices/${cell.row.original.id}/update`, 
            voice_info,
            {
              withCredentials: true, // Include cookies in the request
            }
            )
            .then(response => setUpdate(getRandomInt()))
            .catch(error => {
                console.error('There was an error!', error);
            });
 
        //setTableData([...tableData]); //re-render with new data
      };
    
      return (
        <MaterialReactTable
          columns={columns}
          data={tableData}
          initialState={{ columnVisibility: { elevenlabs_id: false }, density: 'spacious' }}
          editingMode="cell"
          enableEditing
          muiTableHeadCellProps = {{
            //no useTheme hook needed, just use the `sx` prop with the theme callback
            sx: (theme) => ({
              backgroundColor: theme.palette.grey[300],
            }),
          }}
          muiTableBodyCellEditTextFieldProps={({ cell }) => ({
            //onBlur is more efficient, but could use onChange instead
            onBlur: (event) => {
              handleSaveCell(cell, event.target.value);
            },
          })}
          muiTablePaperProps={{
            elevation: 3, //change the mui box shadow
          }}
          renderBottomToolbarCustomActions={() => (
            <Typography sx={{ fontStyle: 'italic', p: '0 1rem' }} component={'span'} variant="body2">
              Double-Click a Cell to Edit
            </Typography>
          )}
        />
        )
}

const Voices = () => {
    const [isLoading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [update, setUpdate] = useState(0);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/voices/`,
          {
            withCredentials: true, // Include cookies in the request
          }
        )
        .then((response) => {
            setTableData(response.data);
            setLoading(false);
        })
        .catch((error) => {
            console.error(error);
        });
    }, [update]);

    return (
        <Box
            margin="50px 100px"
        >
            <Box
                display="flex"
            >
            <Button 
              sx = {{
                margin:"0px 0px 10px 0px"
              }}
              variant="contained" 
              color="primary" 
              onClick={handleOpen} 
            >
              Generate Voice
            </Button>
            </Box>
            {isLoading 
            ? 
            <></>
            :
            <>
                <Table tableData={tableData} setTableData={setTableData} setUpdate={setUpdate}/>
                <AudioDropzone open={open} setOpen={setOpen} setUpdate={setUpdate} />
            </>
    }
    </Box>
    )

}

export default Voices;