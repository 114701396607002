import { useTheme } from '@emotion/react'
import { Box, Typography, IconButton, Button, Paper, CircularProgress, Link  } from '@mui/material'
import { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import RefreshIcon from '@mui/icons-material/Refresh';
import {ReactComponent as ReactLogo} from '../../static/audiorefresh.svg'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined';
import Looks3OutlinedIcon from '@mui/icons-material/Looks3Outlined';
import Looks4OutlinedIcon from '@mui/icons-material/Looks4Outlined';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

function getRandomInt() {
  return Math.floor(Math.random() * 100000);
}

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const GenerateTranslationsButton = ({id, setUpdate, language, language_id , missingTranscriptionSentence, setTableDataUpdate, tableDataLength}) => {
  const [loading, setLoading] = useState(false);

  const handleTranslationGeneration = async () => {
    try {
      setLoading(true)

      const post = axios.post(`${process.env.REACT_APP_BACKEND_URL}/translations/${id}/generate`, 
        [{"name":language, "id":language_id}],
        {
          withCredentials: true, // Include cookies in the request
        }
        )

      await delay(200);
      setUpdate(getRandomInt())
      await post;

    } catch (error) {
      console.error('There was an error!', error);
    } finally {
      setTableDataUpdate(getRandomInt());
      setLoading(false); // Set loading state to false after the response
    }
  };
  return (
    <Button
      variant="contained" 
      color="primary" 
      onClick={handleTranslationGeneration}
      disabled={missingTranscriptionSentence || tableDataLength===0 || loading}
      startIcon={loading ? <CircularProgress size={24} />: null}
  >
      Generate Translations
  </Button>
  )
}

const GenerateAudioFilesButton = ({video_id, setUpdate, language_id ,setTableDataUpdate, missingSentence, tableDataLength}) => {
  
  const [loading, setLoading] = useState(false);

  const handleAllAudioGeneration = async() => {
    try {
      setLoading(true)

      const post = axios.post(`${process.env.REACT_APP_BACKEND_URL}/translations_audio/generate`, 
      {"video_id":video_id, "language_id":language_id},
      {
        withCredentials: true, // Include cookies in the request
      }
      )

      await delay(200);
      setUpdate(getRandomInt())
      await post;

    } catch (error) {
      console.error('There was an error!', error);
    } finally {
      setTableDataUpdate(getRandomInt());
      setLoading(false); // Set loading state to false after the response
    }
  }
  return (
    <Button
      variant="contained" 
      color="primary" 
      onClick={handleAllAudioGeneration}
      disabled={missingSentence || tableDataLength===0 || loading}
      startIcon={loading ? <CircularProgress size={24} />: null}
  >
      Generate Audio Files
  </Button>
  )
}

const GenerateOutputFileButton = ({video_id, setUpdate, language_id ,setTableDataUpdate, missingAudioFile, tableDataLength}) => {
  const [loading, setLoading] = useState(false);
  

  const handleOutputGeneration = async () => {
    try {
      setLoading(true)

      const post = axios.post(`${process.env.REACT_APP_BACKEND_URL}/video_outputs/generate`, 
      {"video_id":video_id, "language_id":language_id},
      {
        withCredentials: true, // Include cookies in the request
      }
      )

      await delay(200);
      setUpdate(getRandomInt())
      await post;

    } catch (error) {
      console.error('There was an error!', error);
    } finally {
      setTableDataUpdate(getRandomInt());
      setLoading(false); // Set loading state to false after the response
    }
  }
  return (
    <Button
      variant="contained" 
      color="primary" 
      onClick={handleOutputGeneration}
      disabled={missingAudioFile || tableDataLength===0 || loading}
      startIcon={loading ? <CircularProgress size={24} />: null}
  >
      Generate Output Files
  </Button>
  )
}

const DownloadOutputFileButton = ({video_id, language_id ,setTableDataUpdate, missingOutputFile, tableDataLength}) => {
  const [loading, setLoading] = useState(false);

  const handleOutputDownload = () => {
    setLoading(true)
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/video_outputs/download?video_id=${video_id}&language_id=${language_id}`, 
    { responseType: 'blob',
      withCredentials: true, // Include cookies in the request
  })
    .then((response) => {
      // Create a temporary download link
      const contentDisposition = response.headers.get('content-disposition')
      const filenameMatch = contentDisposition.split('filename=')[1];
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filenameMatch);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading(false);
      setTableDataUpdate(getRandomInt())
    })
    .catch((error) => {
      console.error('Error downloading ZIP file', error);
    });
  };

  return (
    <Button
      variant="contained" 
      color="primary" 
      onClick={handleOutputDownload}
      disabled={missingOutputFile || loading  || tableDataLength===0}
      startIcon={loading ? <CircularProgress size={24} />: null}
  >
      Download Output Files
  </Button>
  )

}



const handleLockChange = (cell, setTableDataUpdate) => {

  axios.post(`${process.env.REACT_APP_BACKEND_URL}/translations/${cell.row.original.translation_id}/update`, 
  {lock: !cell.row.original.lock},
  {
    withCredentials: true, // Include cookies in the request
  }
  )
  .then(response => setTableDataUpdate(getRandomInt()))
  .catch(error => {
      console.error('There was an error!', error);
  })
};

const AudioPlayer = ({ audioUrl }) => {
  const [audio, setAudio] = useState(new Audio(audioUrl));
  const [isPlaying, setIsPlaying] = useState(false);

  // Update the audio element when audioUrl changes
  useEffect(() => {
    setAudio(new Audio(audioUrl));
    setIsPlaying(false); // Stop playback when audioUrl changes
  }, [audioUrl]);

  const togglePlay = () => {
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    // Add an event listener for the 'ended' event
    const handleEnded = () => {
      setIsPlaying(false); // Update the state when audio playback ends
    };

    audio.addEventListener('ended', handleEnded);

    // Clean up the event listener when the component unmounts
    return () => {
      audio.removeEventListener('ended', handleEnded);
    };
  }, [audio]);

  return (
    <IconButton onClick={togglePlay}>
      {isPlaying ? 
        <PauseCircleOutlineIcon 
          style={{
            height:"25px",
            width:"25px"}}
        /> 
        : 
        <PlayCircleOutlineIcon 
        style={{
          height:"25px",
          width:"25px"}}
      /> 
      }
    </IconButton>
  );
}

const RegenerateAudioIcon = ({video_id, language_id, translation_id, setTableDataUpdate, setUpdateAudio}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const handleAudioRegeneration = () => {
    setLoading(true)

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/translations_audio/${translation_id}/regenerate`, 
    {"video_id":video_id, "language_id":language_id},
    {
      withCredentials: true, // Include cookies in the request
    }
    )
    .then(response => {setTableDataUpdate(getRandomInt());setLoading(false);setUpdateAudio(getRandomInt())})
    .catch(error => {
        console.error('There was an error!', error);
    })
  };

  return (
    <IconButton
      onClick={() => handleAudioRegeneration(translation_id, setTableDataUpdate)}
    >
      {loading ? 
        <CircularProgress size={24} /> 
        : 
        <ReactLogo style={{
          height:"25px",
          width:"25px",
          color: theme.palette.primary[800]
        }}/>
    }
    </IconButton>
  )
}

const RegenerateSentenceIcon = ({video_id, language_id, translation_id, setTableDataUpdate}) => {
  const [loading, setLoading] = useState(false);

  const handleSentenseRegeneration = () => {
    setLoading(true)
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/translations/${translation_id}/regenerate`, 
    {"video_id":video_id, "language_id":language_id},
    {
      withCredentials: true, // Include cookies in the request
    }
    )
    .then(response => {setTableDataUpdate(getRandomInt());setLoading(false)})
    .catch(error => {
        console.error('There was an error!', error);
    });
  };

  return (
    <IconButton
      onClick={() => {handleSentenseRegeneration(translation_id, setTableDataUpdate)}}
    >
      {loading ? 
        <CircularProgress size={24} /> 
        : 
      <RefreshIcon style={{
        height:"25px",
        width:"25px",
      }}/>
    }
    </IconButton>
  )
}

const Table = ({id, update, language, language_id, setMissingSentence, tableData, setTableData, tableDataUpdate, setTableDataUpdate, setMissingTranscriptionSentence, setMissingAudioFile}) => {

    const [isLoading, setLoading] = useState(true);
    const [updateAudio, setUpdateAudio] = useState(null);
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 25, //customize the default page size
    })

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/translations/${id}/table?language=${language}`,
        {
          withCredentials: true, // Include cookies in the request
        }
        )
        .then((response) => {
            setTableData(response.data);
            setLoading(false);
            const checkEmptyTranscriptionSentense = obj => obj.german_sentence === '';
            setMissingTranscriptionSentence(response.data.some(checkEmptyTranscriptionSentense))
            const checkEmptySentense = obj => obj.sentence === null;
            setMissingSentence(response.data.some(checkEmptySentense))
            const checkEmptyAudioFile = obj => obj.audio_id === null;
            setMissingAudioFile(response.data.some(checkEmptyAudioFile))
        })
        .catch((error) => {
            console.error(error);
        });
    }, [update, tableDataUpdate]);
    //store pagination state in your own state

    const columns = useMemo(
        () => [
          {
            accessorKey: 'german_sentence_number', //access nested data with dot notation
            header: 'Sentence Number',
            enableEditing: false,
          },
          {
            accessorKey: 'german_sentence', //access nested data with dot notation
            header: 'German Sentence',
            enableEditing: false,
          },
          {
            accessorKey: 'start_time', //access nested data with dot notation
            header: 'Start',
            enableEditing: false,
          },
          {
            accessorKey: 'end_time', //access nested data with dot notation
            header: 'End',
            enableEditing: false,
          },
          {
            accessorKey: 'voice_name', //access nested data with dot notation
            header: 'Voice',
            enableEditing: false,
          },
          {
            accessorKey: 'sentence',
            header: 'Translation',
            muiTableBodyCellEditTextFieldProps: {
              multiline: true,
            }
          },
          {
            accessorFn: (row) => (row.sentence === null ? "Yes" : "No"),
            header: 'Missing',
            enableEditing: false,
          },
        ],
        [updateAudio],
      );


      const handleSaveCell = (cell, value) => {
        //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
        //tableData[cell.row.index][cell.column.id] = value;
        //send/receive api updates here

        let translation_info = {}
        translation_info[cell.column.id] = value
        translation_info["video_id"] = id
        translation_info["language_id"] = language_id

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/translations/${cell.row.original.translation_id}/update`, 
        translation_info,
        {
          withCredentials: true, // Include cookies in the request
        }
        )
            .then(response => setTableDataUpdate(getRandomInt()))
            .catch(error => {
                console.error('There was an error!', error);
            });
 
        //setTableData([...tableData]); //re-render with new data
      };
    
      return (
        <MaterialReactTable
          columns={columns}
          data={tableData}
          editingMode="cell"
          enableEditing
          enableRowActions
          positionActionsColumn="last"
          autoResetPageIndex={false}
          onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
          state={{ pagination }} //pass the pagination state to the table
          muiTableHeadCellProps = {{
            //no useTheme hook needed, just use the `sx` prop with the theme callback
            sx: (theme) => ({
              backgroundColor: theme.palette.grey[300],
            }),
          }}
          muiTablePaperProps={{
            elevation: 3, //change the mui box shadow
          }}
          muiTableBodyCellProps={({ cell }) => ({
            sx: {
              border: ( cell.row.original.sentence === null)?'1px dotted red':null //add a border between columns
            },
          })}
          muiTableBodyCellEditTextFieldProps={({ cell }) => ({
            //onBlur is more efficient, but could use onChange instead
            onBlur: (event) => {
              handleSaveCell(cell, event.target.value);
            },
          })}
          renderBottomToolbarCustomActions={() => (
            <Typography sx={{ fontStyle: 'italic', p: '0 1rem' }} component={'span'} variant="body2">
              Double-Click a Cell to Edit
            </Typography>
          )}
          renderRowActions={({ cell, row, table }) => 
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
              <IconButton
                onClick={() => {handleLockChange(cell, setTableDataUpdate)}}
              >
                {cell.row.original.lock ? <LockIcon /> : <LockOpenIcon />}
              </IconButton>
              <RegenerateSentenceIcon  video_id={id} language_id={language_id} translation_id={row.original.translation_id} setTableDataUpdate={setTableDataUpdate}/>
              {row.original.audio_url ? 
                <>
                  <AudioPlayer audioUrl = {"https://storage.googleapis.com/" + row.original.audio_url}/>
                  <RegenerateAudioIcon video_id={id} language_id={language_id} setUpdateAudio={setUpdateAudio} translation_id={row.original.translation_id} setTableDataUpdate={setTableDataUpdate} />
                </>
                :
                null
              }
              
            </Box>
          }
        />
        )
}


const TranslationTable = ({id, update, setUpdate, language, language_id}) => {
    const [missingSentence, setMissingSentence] = useState(true);
    const [missingTranscriptionSentence, setMissingTranscriptionSentence] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [tableDataUpdate, setTableDataUpdate] = useState(null);
    const [missingAudioFile, setMissingAudioFile] = useState(false);
    const [missingOutputFile, setMissingOutputFile] = useState(true);

    useEffect(() => {
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/video_outputs/check`, 
      {"video_id":id, "language_id":language_id},
      {
        withCredentials: true, // Include cookies in the request
      }
      )
      .then((response) => {
          setMissingOutputFile(!response.data["available"])
      })
      .catch((error) => {
          console.error(error);
      });
  }, [tableDataUpdate]);

    const gridTemplate = `
      "a b c d"
      `

    return (
        
        <Box>
              <Paper 
                elevation={2} 
                style={{ 
                  padding: '20px',
                  marginTop: '20px',
                  marginBottom: '10px'
                }}
              >
                <Box
                  display="grid"
                  sx = {{
                        gridTemplateColumns: "repeat(4, minmax(220px, 1fr))",
                        gridTemplateAreas: gridTemplate,
                      }}
                >     
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <LooksOneOutlinedIcon fontSize="large" sx={{margin:"20px"}}/>
                    <GenerateTranslationsButton id={id} language={language} setUpdate={setUpdate} language_id={language_id} missingTranscriptionSentence={missingTranscriptionSentence} setTableDataUpdate={setTableDataUpdate} tableDataLength={tableData.length}/>
                  </Box>
                  
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <LooksTwoOutlinedIcon fontSize="large" sx={{margin:"20px"}}/>
                    <GenerateAudioFilesButton video_id={id} setUpdate={setUpdate} language_id={language_id} setTableDataUpdate={setTableDataUpdate} missingSentence={missingSentence} tableDataLength={tableData.length}/>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Looks3OutlinedIcon fontSize="large" sx={{margin:"20px"}}/>
                    <GenerateOutputFileButton video_id={id} setUpdate={setUpdate} language_id={language_id} setTableDataUpdate={setTableDataUpdate} missingAudioFile={missingAudioFile} tableDataLength={tableData.length}/>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Looks4OutlinedIcon fontSize="large" sx={{margin:"20px"}}/>
                    <DownloadOutputFileButton video_id={id} language_id={language_id} setTableDataUpdate={setTableDataUpdate} missingOutputFile={missingOutputFile} tableDataLength={tableData.length}/>
                  </Box>
                </Box>
              </Paper>
            <Table tableDataUpdate={tableDataUpdate} setTableDataUpdate={setTableDataUpdate} id={id} update={update} missingTranscriptionSentence={missingTranscriptionSentence} setMissingTranscriptionSentence={setMissingTranscriptionSentence} setMissingSentence={setMissingSentence} tableData={tableData} setTableData={setTableData} setMissingAudioFile={setMissingAudioFile} language={language} language_id={language_id}/>

    </Box>
    )

}

export default TranslationTable;