import { Box, Typography, IconButton, Button, CircularProgress } from '@mui/material'
import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import {
    Edit as EditIcon,
  } from '@mui/icons-material';


function getRandomInt() {
    return Math.floor(Math.random() * 100000);
}

const NewDialog = ({open, setOpen, setUpdate}) => {
    const [titleValue, setTitleValue] = useState('');
    const [videoid, setVideoidValue] = useState('');
    const [error, setError] = useState('');

    const handleTitleChange = (event) => {
        setTitleValue(event.target.value);
      };

    const handleVideoidChange = (event) => {
        setVideoidValue(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
      };

    const handleSave = () => {
      if (titleValue && videoid) {
        // Access the value of the TextField here (in textFieldValue)

        const video_info = { title: titleValue, youtube_video_id:  videoid};

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/video/new`, video_info,
        {
          withCredentials: true, // Include cookies in the request
        }
        )
            .then(response => setUpdate(getRandomInt()))
            .catch(error => {
                console.error('There was an error!', error);
            });
        setOpen(false);
        }
        else {
          // Display an error message
          setError('Both fields are mandatory');
      }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Video</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Add new Video
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Title"
                    type="title"
                    fullWidth
                    variant="standard"
                    required
                    onChange={handleTitleChange}
                />
                <TextField
                    margin="dense"
                    id="youtube_video_id"
                    label="Video ID"
                    type="youtube_video_id"
                    fullWidth
                    variant="standard"
                    required  
                    onChange={handleVideoidChange}
                />
                {error && <div className="error-message">{error}</div>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave}>Add</Button>
            </DialogActions>
        </Dialog>
    )
}

const DeleteButton = ({video_id, deletion, setUpdate}) => {
  const [loading, setLoading] = useState(false);

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleDeleteClick = async () => {
    if (window.confirm("Löschen?")) {
      try {
        // First POST request without await
        const firstPost = axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/video/${video_id}/delete`,
          {},
          {
            withCredentials: true,
          }
        );

        await delay(200);
        // Perform other actions here
        setUpdate(getRandomInt())
        // Now, await the first POST request
        await firstPost;

        setUpdate(getRandomInt());
      }
      catch (error) {
        console.error('There was an error!', error);
      }
    }
  };

  return (
    <>
    {!deletion ? 
      <IconButton
        color="error"
        onClick={() => handleDeleteClick()}
    >
        <DeleteOutlineOutlinedIcon />
    </IconButton>
    :
      <CircularProgress style={{margin:"9px"}} size={18}/>
    }
  </>
  )
}

const Table = ({tableData, setUpdate}) => {
    const navigate = useNavigate();

    const columns = useMemo(
        () => [
          {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 5,
            enableEditing: false
          },
          {
            accessorKey: 'date', //access nested data with dot notation
            header: 'Date',
            enableEditing: false,
            type: 'date',
          },
          {
            accessorKey: 'title',
            header: 'Title',
          },
          {
            accessorKey: 'youtube_video_id', //normal accessorKey
            header: 'Video ID',
          },
          {
            title: "FirstAction",
            header: 'Action',
            enableEditing: false,
            Cell: ({ cell }) => (
                <Box
                  display="flex"
                  alignItems="center"
                  gap="5px"
                >
                    <IconButton
                        onClick={() => {
                            navigate(`/${cell.row.original.id}`)
                        }}
                        >
                        <EditIcon />
                    </IconButton>
                    <DeleteButton video_id={cell.row.original.id} deletion={cell.row.original.delete} setUpdate={setUpdate}/>
                </Box>
            )
          },
        ],
        [],
      );


      const handleSaveCell = (cell, value) => {
        //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
        //tableData[cell.row.index][cell.column.id] = value;
        //send/receive api updates here

        let video_info = {}
        video_info[cell.column.id] = value

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/video/${cell.row.original.id}/update`, 
        video_info,
        {
          withCredentials: true, // Include cookies in the request
        }
        )
            .then(response => setUpdate(getRandomInt()))
            .catch(error => {
                console.error('There was an error!', error);
            });
 
        //setTableData([...tableData]); //re-render with new data
      };
    
      return (
        <MaterialReactTable
          columns={columns}
          data={tableData}
          editingMode="cell"
          enableEditing
          muiTableHeadCellProps = {{
            //no useTheme hook needed, just use the `sx` prop with the theme callback
            sx: (theme) => ({
              backgroundColor: theme.palette.grey[300],
            }),
          }}
          muiTablePaperProps={{
            elevation: 3, //change the mui box shadow
          }}
          muiTableBodyCellEditTextFieldProps={({ cell }) => ({
            //onBlur is more efficient, but could use onChange instead
            onBlur: (event) => {
              handleSaveCell(cell, event.target.value);
            },
          })}
          renderBottomToolbarCustomActions={() => (
            <Typography sx={{ fontStyle: 'italic', p: '0 1rem' }} variant="body2">
              Double-Click a Cell to Edit
            </Typography>
          )}
        />
        )
}

const Videos = () => {
    const [isLoading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(0);

    const handleClickOpen = () => {
      setOpen(true);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/`,
        {
          withCredentials: true, // Include cookies in the request
        }
        )
        .then((response) => {
            setTableData(response.data);
            setLoading(false);
        })
        .catch((error) => {
            console.error(error);
        });
    }, [update]);

    return (
        <Box
            margin="50px 100px"
        >
            <Box
                display="flex"
            >
                <Button 
                    sx = {{
                        margin:"0px 0px 10px 0px"
                    }}
                    variant="contained" 
                    color="primary" 
                    onClick={handleClickOpen}
                >
                    Create Video
                </Button>
            </Box>
            {isLoading 
            ? 
            <></>
            :
            <>
                <Table tableData={tableData} setTableData={setTableData} setUpdate={setUpdate}/>
                <NewDialog open={open} setOpen={setOpen} setUpdate={setUpdate} />
            </>
    }
    </Box>
    )

}

export default Videos;