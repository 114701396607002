import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TranscriptionTable from '../transcriptionTable';
import TranslationTable from '../translationsTable';
import axios from 'axios';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // Material-UI icon for status indicators


function getRandomInt() {
  return Math.floor(Math.random() * 100000);
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ m: "20px 0px 0px 0px" }}>
          <Typography component={'span'} variant="body2">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    key:`simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BasicTabs = ({id, update, setUpdate}) => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const [tabData, setTabData] = useState([]);
  const [jobData, setJobData] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    navigate(`?tab=${newValue}`);
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/languages/active`,
    {
      withCredentials: true, // Include cookies in the request
    }
    )
    .then((response) => {
      setTabData(response.data);
      // Check if the tab data is fetched and set the value accordingly
      if (response.data.length > 0) {
        // Get the selected tab from the URL query parameter (if available)
        const searchParams = new URLSearchParams(window.location.search);
        const tab = searchParams.get('tab');
        if (tab !== null) {
          const tabValue = parseInt(tab, 10);
          if (tabValue >= 0 && tabValue < response.data.length + 1) {
            setValue(tabValue);
          }
        }
      }
    })
    .catch((error) => {
        console.error(error);
    });
  }, []);

  useEffect(() => {
      let intervalId;
      const fetchData = () => {
        axios
          .get(`${process.env.REACT_APP_BACKEND_URL}/jobs/?video_id=${id}`, {
            withCredentials: true, // Include cookies in the request
          })
          .then((response) => {
            setJobData(response.data);

            const hasInProgressOrWaitingJobs = response.data.some(
              (job) => job.stage === 'in_progress' || job.stage === 'waiting'
            );

            if (!hasInProgressOrWaitingJobs) {
              clearInterval(intervalId); // Clear the interval if no relevant jobs are found
            }
          })
          .catch((error) => {
            console.error(error);
          });
      };

      fetchData(); // Initial data fetch
      intervalId = setInterval(fetchData, 5000); // Set up the interval
      return () => clearInterval(intervalId); // Cleanup the interval on unmount
    }, [update]);


  const columns = tabData.map((item) => ({"name":item["name"], "id":item["id"]}))

  return (
    <Box sx={{ 
        margin: '50px 0px 50px 0px'
        }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label=
           {
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography>Transcription</Typography>
              <FiberManualRecordIcon
                style={{
                  color: jobData.find((item) => item.type === "transcription" && item.language_id === null)?.stage === 'in_progress'
                    ? "blue"
                    : jobData.find((item) => item.type === "transcription" && item.language_id === null)?.stage === 'done'
                    ? "transparent"
                    : "transparent"
                    ,
                  fontSize: 10,
                }}
              />
            </Box>
          }
          {...a11yProps(0)} />
          {columns.map((item, index) => <Tab label={
                                          <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                          >
                                            <Typography>{item.name}</Typography>
                                            <Box gap="5px" display="flex">
                                              <FiberManualRecordIcon
                                                style={{
                                                  color: jobData.find((job_item) => job_item.type === "translation" && job_item.language_id === item.id)?.stage === 'in_progress'
                                                    ? "blue"
                                                    : jobData.find((job_item) => job_item.type === "translation" && job_item.language_id === item.id)?.stage === 'waiting'
                                                    ? "#FFD700"
                                                    : (jobData.find((job_item) => job_item.type === "translation" && job_item.language_id === item.id)?.stage === 'done') && (jobData.find((job_item) => job_item.language_id === item.id && job_item.stage === "waiting") || jobData.find((job_item) => job_item.language_id === item.id && job_item.stage === "in_progress"))
                                                    ? "green"
                                                    : "transparent"
                                                    ,
                                                  fontSize: 10,
                                                }}
                                              />
                                              <FiberManualRecordIcon
                                                style={{
                                                  color: jobData.find((job_item) => job_item.type === "audio" && job_item.language_id === item.id)?.stage === 'in_progress'
                                                    ? "blue"
                                                    : jobData.find((job_item) => job_item.type === "audio" && job_item.language_id === item.id)?.stage === 'waiting'
                                                    ? "FFD700"
                                                    : (jobData.find((job_item) => job_item.type === "audio" && job_item.language_id === item.id)?.stage === 'done') && (jobData.find((job_item) => job_item.language_id === item.id && job_item.stage === "waiting") || jobData.find((job_item) => job_item.language_id === item.id && job_item.stage === "in_progress"))
                                                    ? "green"
                                                    : "transparent"
                                                    ,
                                                  fontSize: 10,
                                                }}
                                              />
                                              <FiberManualRecordIcon
                                                style={{
                                                  color: jobData.find((job_item) => job_item.type === "output" && job_item.language_id === item.id)?.stage === 'in_progress'
                                                    ? "blue"
                                                    : jobData.find((job_item) => job_item.type === "output" && job_item.language_id === item.id)?.stage === 'waiting'
                                                    ? "FFD700"
                                                    : (jobData.find((job_item) => job_item.type === "output" && job_item.language_id === item.id)?.stage === 'done') && (jobData.find((job_item) => job_item.language_id === item.id && job_item.stage === "waiting") || jobData.find((job_item) => job_item.language_id === item.id && job_item.stage === "in_progress"))
                                                    ? "green"
                                                    : "transparent"
                                                    ,
                                                  fontSize: 10,
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        } 
                                          {...a11yProps(index+1)} 
                                        />)}
        </Tabs>
      </Box>
        
        <CustomTabPanel value={value} index={0} key={0}>
          <TranscriptionTable id={id} update={update} setUpdate={setUpdate} />
        </CustomTabPanel>
        {columns.map((item, index) => 
            <CustomTabPanel value={value} index={index+1} key={index+1}>
              <TranslationTable id={id} language={item.name} language_id={item.id} update={update} setUpdate={setUpdate}/>
            </CustomTabPanel>
        )}
    </Box>
  );
}

export default BasicTabs;