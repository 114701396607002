import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';

function getRandomInt() {
  return Math.floor(Math.random() * 100000);
}


function LanguageSelectionDescription({open, setOpen, id, setUpdate}) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [languages, setLanguages] = useState([])
  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/languages/active`,
    {
      withCredentials: true,
    }
    )
    .then((response) => {
        setLanguages(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
}, []);

 
  const handleClose = () => {
    setOpen(false);
    setCheckedItems([])
    setMessage(null)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      // Send files and additional info to Flask endpoint
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/video/${id}/description/translate`, 
      checkedItems,
      {
        withCredentials: true,
      }
      )

      setMessage(null);
      setOpen(false);
      setCheckedItems([])
      setUpdate(getRandomInt())
    } catch (error) {
      setMessage('Error uploading files.');
    } finally {
      setLoading(false); // Set loading state to false after the response
    }
  };


  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;

    if (checked) {
      setCheckedItems([...checkedItems, { id: parseInt(value), name: name }]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item.name !== name));
    }
  };



  return (
    <Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography 
            variant="h6"
            sx={{
              "margin":"0px 0px 10px 0px",
            }}
            >
              Select Languages:
          </Typography>
          <Box
            margin="0px 0px 10px 0px"
          >
            <form onSubmit={handleSubmit}>
                <FormGroup>
                    {
                        languages.map((item) => 
                            <FormControlLabel control={<Checkbox onChange={handleCheckboxChange}/>} key={item["id"]} value={item["id"]} name={item["english_name"]} label={item["name"]} />
                        )
                    }
                </FormGroup>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading} // Disable the button during loading
                >
                    {loading ? <CircularProgress size={24} /> : 'Submit'}{/* Display loading icon when loading */}
                </Button>
            </form>
          </Box>

          <Typography>{message}</Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
}



export default LanguageSelectionDescription;