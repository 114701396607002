import React, { useState } from 'react';
import { useTheme } from '@emotion/react'
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
  Box,
  List,
  ListItem,
  IconButton,
  CircularProgress
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

function getRandomInt() {
  return Math.floor(Math.random() * 100000);
}

function AudioDropzone({open, setOpen, setUpdate}) {
  const theme = useTheme()
  const [files, setFiles] = useState([]);
  const [voiceName, setVoiceName] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

 
  const handleClose = () => {
    setOpen(false);
    setFiles([]);
    setVoiceName('');
  };

  const onDrop = (acceptedFiles) => {
    // Filter out non-audio files
    const audioFiles = acceptedFiles.filter(
      (file) => file.type.startsWith('audio/')
    );
    setFiles([...files, ...audioFiles]);
  };

  const handleFileRemove = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('audioFiles', file);
      });

      // Append the additional info to FormData
      formData.append('voiceName', voiceName);

      // Send files and additional info to Flask endpoint
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/voices/new`, 
      formData,
      {
        withCredentials: true, // Include cookies in the request
      }
      );

      // Clear the form and close the popup
      setFiles([]);
      setVoiceName('');
      setMessage('Files uploaded successfully!');
      setOpen(false);
      setUpdate(getRandomInt())
    } catch (error) {
      setMessage('Error uploading files.');
    } finally {
      setLoading(false); // Set loading state to false after the response
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'audio/*', // Only accept audio files
  });

  return (
    <Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography 
            variant="h6"
            sx={{
              "margin":"0px 0px 10px 0px"
            }}
            >
              Upload Audio Files
          </Typography>
          <Box
            margin="0px 0px 10px 0px"
          >
            <TextField
              label="Voice Name"
              variant="outlined"
              fullWidth
              value={voiceName}
              onChange={(e) => setVoiceName(e.target.value)}
            />
          </Box>
          <Box 
            {...getRootProps()} 
            border='2px dashed #cccccc'
            borderRadius='4px'
            padding='50px 20px'
            textAlign='center'
            cursor='pointer'
            marginBottom="10px"
          >
            <input {...getInputProps()} />
            <p>Drag 'n' drop some audio files here, or click to select files</p>
          </Box>
          <Box
            margin="0px 0px 10px 0px"
            border={`1px solid ${theme.palette.grey[400]}`}
            borderRadius="4px"
            padding="10px"
          >
            <Typography 
              variant="subtitle1"
            >
              Uploaded Files:
            </Typography>
            <List
              sx = {{
              listStyleType: 'disc',
              pl: 2,
              }}
            >
              {files.map((file, index) => (
                <ListItem 
                  sx={{
                    display: 'list-item', 
                    padding: "0px 0px"
                  }} 
                  key={index}
                >
                  {file.name}
                  <IconButton
                    color="primary"
                    onClick={() => handleFileRemove(index)}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={files.length === 0 || loading} // Disable the button during loading
          >
            {loading ? <CircularProgress size={24} /> : 'Submit'}{/* Display loading icon when loading */}
          </Button>
          <Typography>{message}</Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
}



export default AudioDropzone;