import { useTheme } from '@emotion/react'
import { Box, Typography, IconButton, Button, MenuItem, FormControl, InputLabel, Select  } from '@mui/material'
import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LanguageSelection from '../languageSelection';
import VideoPopup from '../VideoPopup';

function getRandomInt() {
  return Math.floor(Math.random() * 100000);
}



const handleDropdownChange = (event, cell, setTableDataUpdate) => {

  let transcription_info = {}
  transcription_info[cell.column.id] = event.target.value

  axios.post(`${process.env.REACT_APP_BACKEND_URL}/transcriptions/${cell.row.original.transcription_id}/update`, 
  transcription_info,
  {
    withCredentials: true, // Include cookies in the request
  }
  )
      .then(response => setTableDataUpdate(getRandomInt()))
      .catch(error => {
          console.error('There was an error!', error);
      });

};

const handleRowInsert = (row, setTableDataUpdate) => {
  axios.post(`${process.env.REACT_APP_BACKEND_URL}/transcriptions/${row.original.transcription_id}/insert`, {},
  {
    withCredentials: true, // Include cookies in the request
  }
  )
  .then(response => setTableDataUpdate(getRandomInt()))
  .catch(error => {
      console.error('There was an error!', error);
  });
};

const handleRowDelete = (row, setTableDataUpdate) => {
  if (window.confirm("Löschen?")) {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/transcriptions/${row.original.transcription_id}/delete`, {},
    {
      withCredentials: true, // Include cookies in the request
    }
    )
    .then(response => setTableDataUpdate(getRandomInt()))
    .catch(error => {
        console.error('There was an error!', error);
    });
  }
}

const handleLockChange = (cell, setTableDataUpdate) => {

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/transcriptions/${cell.row.original.transcription_id}/update`, 
    {lock: !cell.row.original.lock},
    {
      withCredentials: true, // Include cookies in the request
    }
    )
    .then(response => setTableDataUpdate(getRandomInt()))
    .catch(error => {
        console.error('There was an error!', error);
    })
};

const DropdownColumn = ({ label, options, value, onChange }) => {
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <InputLabel>Voice</InputLabel>
      <Select label={label} value={value} onChange={onChange}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};



const Table = ({id, update, setMissingSentence, tableData, setTableData, tableDataUpdate, setTableDataUpdate}) => {
    const theme = useTheme();
    const [isLoading, setLoading] = useState(true);

    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 25, //customize the default page size
    })
    const [dropdownData, setDropdownData] = useState([]); // Initial empty array


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/voices/active`,
        {
          withCredentials: true, // Include cookies in the request
        }
        )
        .then((response) => {
          setDropdownData(response.data);
        })
        .catch((error) => {
            console.error(error);
        });
    }, [update, tableDataUpdate]);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/transcriptions/${id}`,
        {
          withCredentials: true, // Include cookies in the request
        }
        )
        .then((response) => {
            setTableData(response.data);
            setLoading(false);
            const checkEmptySentense = obj => obj.sentence === '';
            setMissingSentence(response.data.some(checkEmptySentense))
        })
        .catch((error) => {
            console.error(error);
        });
    }, [update, tableDataUpdate]);
    //store pagination state in your own state

    const columns = useMemo(
        () => [
          {
            accessorKey: 'transcription_id', //access nested data with dot notation
            header: 'ID',
            size: 5,
            enableEditing: false,
          },
          {
            accessorKey: 'sentence_number', //access nested data with dot notation
            header: 'Sentence Number',
            size: 5,
            enableEditing: false,
          },
          {
            accessorKey: 'sentence', //access nested data with dot notation
            header: 'Sentence',
            enableEditing: true,
            muiTableBodyCellEditTextFieldProps: {
              multiline: true,
            }
          },
          {
            accessorKey: 'start_time',
            header: 'Start',
          },
          {
            accessorKey: 'end_time',
            header: 'End',
          },
          {
            accessorFn: (row) => (row.end_time-row.start_time).toFixed(2),
            header: 'Length',
            enableEditing: false,
          },
          {
            title: "YoutubeVideo",
            header: 'Youtube Video',
            enableEditing: false,
            Cell: ({ cell }) => (
                  <VideoPopup video_id={cell.row.original.video_id} start_time={cell.row.original.start_time} end_time={cell.row.original.end_time}/> 
                  )
            },
          {
            title: "Voice",
            header: 'Voice',
            accessorKey: 'voice_id',
            enableEditing: false,
            Cell: ({ cell }) => (
              <>
                <DropdownColumn 
                  label="Voice"
                  options={ 
                    dropdownData.map((item) => ({value: item["id"], label: item["name"]}))
                  }
                  value={cell.row.original.voice_id}
                  onChange={event => handleDropdownChange(event,cell,setTableDataUpdate)}
                  />
                </>
            )
          },
          {
            accessorKey: 'creation_type',
            header: 'Type',
            enableEditing: false,
          },
        ],
        [dropdownData,tableDataUpdate],
      );


      const handleSaveCell = (cell, value) => {
        //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
        //tableData[cell.row.index][cell.column.id] = value;
        //send/receive api updates here
        let transcription_info = {}
        transcription_info[cell.column.id] = value

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/transcriptions/${cell.row.original.transcription_id}/update`, 
        transcription_info,
        {
          withCredentials: true, // Include cookies in the request
        }
        )
            .then(response => setTableDataUpdate(getRandomInt()))
            .catch(error => {
                console.error('There was an error!', error);
            });
 
        //setTableData([...tableData]); //re-render with new data
      };
    
      return (
        <MaterialReactTable
          columns={columns}
          data={tableData}
          editingMode="cell"
          enableEditing
          enableRowActions
          positionActionsColumn="last"
          autoResetPageIndex={false}
          onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
          state={{ pagination }} //pass the pagination state to the table
          muiTableHeadCellProps = {{
            //no useTheme hook needed, just use the `sx` prop with the theme callback
            sx: (theme) => ({
              backgroundColor: theme.palette.grey[300],
            }),
          }}
          muiTablePaperProps={{
            elevation: 3, //change the mui box shadow
          }}
          muiTableBodyCellProps={({ cell, row }) => ({
            sx: {
              border: ((cell.row.original.creation_type === "manual") && (cell.column.id === "start_time") && (!cell.row.original.lock) )? `1px dotted ${theme.palette.greenAccent[500]}`:((cell.row.original.sentence === "") && (cell.column.id === "sentence") && (!cell.row.original.lock)?'1px dotted red':null) //add a border between columns
            },
          })}
          muiTableBodyCellEditTextFieldProps={({ cell }) => ({
            //onBlur is more efficient, but could use onChange instead
            onBlur: (event) => {
              handleSaveCell(cell, event.target.value);
            },
          })}
          renderBottomToolbarCustomActions={() => (
            <Typography sx={{ fontStyle: 'italic', p: '0 1rem' }} component={'span'} variant="body2">
              Double-Click a Cell to Edit
            </Typography>
          )}
          renderRowActions={({ cell, row }) => (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
              <IconButton
                onClick={() => {handleLockChange(cell, setTableDataUpdate)}}
              >
                {cell.row.original.lock ? <LockIcon /> : <LockOpenIcon />}
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {handleRowInsert(row, setTableDataUpdate)}}
              >
                <KeyboardDoubleArrowDownOutlinedIcon />
              </IconButton>
              <IconButton
                color="redAccent"
                onClick={() => {handleRowDelete(row, setTableDataUpdate)}}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>
          )}
        />
        )
}


const TranscriptionTable = ({id, update, setUpdate}) => {
    const [missingSentence, setMissingSentence] = useState(false);
    const [tableData, setTableData] = useState([]);
    
    const [tableDataUpdate, setTableDataUpdate] = useState(null);

    return (
        <Box>
          <Box
            display="flex"
            gap="10px"
          >
            <LanguageSelection setUpdate={setUpdate} type="translate" missingSentence={missingSentence} tableDataLength={tableData.length} setTableDataUpdate={setTableDataUpdate} id={id}/>
            <LanguageSelection setUpdate={setUpdate}  type="all" missingSentence={missingSentence} tableDataLength={tableData.length} setTableDataUpdate={setTableDataUpdate} id={id}/>
          </Box>
          <Table tableDataUpdate={tableDataUpdate} setTableDataUpdate={setTableDataUpdate} id={id} update={update} setMissingSentence={setMissingSentence} tableData={tableData} setTableData={setTableData}/>
        </Box>
    )

}

export default TranscriptionTable;