import { Box, Typography, IconButton, Button, Checkbox } from '@mui/material'
import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';



function getRandomInt() {
    return Math.floor(Math.random() * 100000);
}


const NewDialog = ({open, setOpen, setUpdate}) => {
    const [nameValue, setNameValue] = useState('');
    const [englishNameValue, setEnglishNameValue] = useState('');


    const handleNameChange = (event) => {
        setNameValue(event.target.value);
      };

      const handleEnglishNameChange = (event) => {
        setEnglishNameValue(event.target.value);
      };


    const handleClose = () => {
        setOpen(false);
      };

    const handleSave = () => {
        const language_info = { name: nameValue, english_name: englishNameValue};

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/languages/new`, 
        language_info,
        {
          withCredentials: true, // Include cookies in the request
        })
            .then(response => setUpdate(getRandomInt()))
            .catch(error => {
                console.error('There was an error!', error);
            });
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Language</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Add new Language
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="name"
                    fullWidth
                    variant="standard"
                    onChange={handleNameChange}
                />
                <TextField
                    margin="dense"
                    id="english_name"
                    label="English name (lowercase)"
                    type="english_name"
                    fullWidth
                    variant="standard"
                    onChange={handleEnglishNameChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave}>Add</Button>
            </DialogActions>
        </Dialog>
    )
}
  
const Table = ({tableData, setUpdate}) => {
    const columns = useMemo(
        () => [
          {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 5,
            enableEditing: false
          },
          {
            accessorKey: 'date', //access nested data with dot notation
            header: 'Date',
            enableEditing: false
          },
          {
            accessorKey: 'name',
            header: 'Name',
          },
          {
            accessorKey: 'english_name',
            header: 'English (lowercase)',
          },
          {
            title: "Active",
            header: 'Active',
            accessorKey: 'active',
            enableEditing: false,
            Cell: ({ cell }) => (
              <Checkbox 
                checked={cell.row.original.active} 
                onClick={() => {
                    axios.post(`${process.env.REACT_APP_BACKEND_URL}/languages/${cell.row.original.id}/update`, {active: !cell.row.original.active},
                    {
                      withCredentials: true, // Include cookies in the request
                    }
                    )
                    .then(response => setUpdate(getRandomInt()))
                    .catch(error => {
                        console.error('There was an error!', error);
                    });
                }} />
            )
          },
          {
            title: "FirstAction",
            header: 'Action',
            enableEditing: false,
            Cell: ({ cell }) => (
                <Box>
                    <IconButton
                        color="error"
                        onClick={() => {
                            window.confirm("Löschen?") ?
                            axios.post(`${process.env.REACT_APP_BACKEND_URL}/languages/${cell.row.original.id}/delete`, {},
                            {
                              withCredentials: true, // Include cookies in the request
                            })
                            .then(response => setUpdate(getRandomInt()))
                            .catch(error => {
                                console.error('There was an error!', error);
                            }):
                            console.log("test")
                        }}
                    >
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </Box>
            )
          },
        ],
        [],
      );


      const handleSaveCell = (cell, value) => {
        //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
        //tableData[cell.row.index][cell.column.id] = value;
        //send/receive api updates here

        let language_info = {}
        language_info[cell.column.id] = value

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/languages/${cell.row.original.id}/update`, 
        language_info,
        {
          withCredentials: true, // Include cookies in the request
        }
        )
            .then(response => setUpdate(getRandomInt()))
            .catch(error => {
                console.error('There was an error!', error);
            });
      };
    
      return (
        <MaterialReactTable
          columns={columns}
          data={tableData}
          editingMode="cell"
          enableEditing
          muiTableHeadCellProps = {{
            //no useTheme hook needed, just use the `sx` prop with the theme callback
            sx: (theme) => ({
              backgroundColor: theme.palette.grey[300],
            }),
          }}
          muiTablePaperProps={{
            elevation: 3, //change the mui box shadow
          }}
          muiTableBodyCellEditTextFieldProps={({ cell }) => ({
            //onBlur is more efficient, but could use onChange instead
            onBlur: (event) => {
              handleSaveCell(cell, event.target.value);
            },
          })}
          renderBottomToolbarCustomActions={() => (
            <Typography sx={{ fontStyle: 'italic', p: '0 1rem' }} component={'span'} variant="body2">
              Double-Click a Cell to Edit
            </Typography>
          )}
        />
        )
}

const Languages = () => {
    const [isLoading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(0);

    
    const handleClickOpen = () => {
      setOpen(true);
    };


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/languages/`,
        {
          withCredentials: true, // Include cookies in the request
        })
        .then((response) => {
            setTableData(response.data);
            setLoading(false);
        })
        .catch((error) => {
            console.error(error);
        });
    }, [update]);

    return (
        <Box
            margin="50px 100px"
        >
            <Box
                display="flex"
            >
                <Button 
                    sx = {{
                        margin:"0px 0px 10px 0px"
                    }}
                    variant="contained" 
                    color="primary" 
                    onClick={handleClickOpen}
                >
                    Create Language
                </Button>
            </Box>
            {isLoading 
            ? 
            <></>
            :
            <>
                <Table tableData={tableData} setTableData={setTableData} setUpdate={setUpdate}/>
                <NewDialog open={open} setOpen={setOpen} setUpdate={setUpdate} />
            </>
    }
    </Box>
    )

}

export default Languages;