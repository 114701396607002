import React, { useState, useEffect, useMemo  } from 'react';
import { Box,Container,Typography, TextField, Button, IconButton, Checkbox, CssBaseline, Grid } from '@mui/material';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

function getRandomInt() {
  return Math.floor(Math.random() * 100000);
}


const UserTable = ({tableData, setUpdate}) => {

  const columns = useMemo(
      () => [
        {
          accessorKey: 'id', //access nested data with dot notation
          header: 'Id',
          size: 5,
          enableEditing: false
        },
        {
          accessorKey: 'username', //access nested data with dot notation
          header: 'Username',
          enableEditing: false
        },
        {
          title: "is_admin",
          header: 'Admin',
          accessorKey: 'is_admin',
          enableEditing: false,
          Cell: ({ cell }) => (
            <Checkbox 
              checked={cell.row.original.is_admin} 
              onClick={() => {
                  axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/${cell.row.original.id}/update`, 
                  {is_admin: !cell.row.original.is_admin},
                  {
                    withCredentials: true, // Include cookies in the request
                  }
                  )
                  .then(response => setUpdate(getRandomInt()))
                  .catch(error => {
                      console.error('There was an error!', error);
                  });
              }} />
          )
        },
        {
          title: "Action",
          header: 'Action',
          enableEditing: false,
          Cell: ({ cell }) => (
              <Box>
                  <IconButton
                      color="error"
                      onClick={() => {
                          window.confirm("Löschen?") ?
                          axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/${cell.row.original.id}/delete`, {},
                          {
                            withCredentials: true, // Include cookies in the request
                          }
                          )
                          .then(response => setUpdate(getRandomInt()))
                          .catch(error => {
                              console.error('There was an error!', error);
                          }):
                          console.log("test")
                      }}
                  >
                      <DeleteOutlineOutlinedIcon />
                  </IconButton>
              </Box>
          )
        },
      ],
      [],
    );


    const handleSaveCell = (cell, value) => {
      //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
      //tableData[cell.row.index][cell.column.id] = value;
      //send/receive api updates here


      axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/${cell.row.original.id}/update`, 
      {"is_admin": !cell.row.original.is_admin},
      {
        withCredentials: true, // Include cookies in the request
      }
      )
          .then(response => setUpdate(getRandomInt()))
          .catch(error => {
              console.error('There was an error!', error);
          });
    };
  
    return (
      <MaterialReactTable
        columns={columns}
        data={tableData}
        editingMode="cell"
        enableEditing
        muiTableHeadCellProps = {{
          //no useTheme hook needed, just use the `sx` prop with the theme callback
          sx: (theme) => ({
            backgroundColor: theme.palette.grey[300],
          }),
        }}
        muiTableBodyCellEditTextFieldProps={({ cell }) => ({
          //onBlur is more efficient, but could use onChange instead
          onBlur: (event) => {
            handleSaveCell(cell, event.target.value);
          },
        })}
        renderBottomToolbarCustomActions={() => (
          <Typography sx={{ fontStyle: 'italic', p: '0 1rem' }} component={'span'} variant="body2">
            Double-Click a Cell to Edit
          </Typography>
        )}
      />
      )
}

function User() {
  const [isLoading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/`,
    {
      withCredentials: true, // Include cookies in the request
    }
    )
    .then((response) => {
        setTableData(response.data);
        setLoading(false);
    })
    .catch((error) => {
        console.error(error);
    });
  }, [update]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/register`, 
    {"username":data.get("username"), "password": data.get("password")},
    {
      withCredentials: true, // Include cookies in the request
    }
    )
    .then((response) => setUpdate(getRandomInt()))
    .catch(error => {
      if (error.status === 401) {
        alert("Invalid Credentials")
      }
      });
  };

  return (
    <Box
      margin="50px 100px"
    >
      <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
          sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
          }}
          >
          <Typography component="h1" variant="h5">
              Register
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
              <Grid item xs={12}>
                  <TextField
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  />
              </Grid>
              <Grid item xs={12}>
                  <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  />
              </Grid>
              </Grid>
              <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              >
                Register
              </Button>
          </Box>
          </Box>
      </Container>
      <UserTable tableData={tableData} setTableData={setTableData} setUpdate={setUpdate}/>
    </Box>
  );
}

export default User