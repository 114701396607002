import { Box, IconButton  } from '@mui/material'
import { useState } from 'react';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';


const VideoPopup = ({ video_id, start_time, end_time }) => {
    const [videoOpen, setVideoOpen] = useState(false);
    const handleOpen = () => {
      setVideoOpen(true)
    }
  
    const handleClose = () => {
      setVideoOpen(false)
    }
    return (
      <Box>
        <IconButton onClick={handleOpen}>
          <OpenInNewIcon />
        </IconButton>
        <Dialog open={videoOpen} onClose={handleClose} maxWidth="md">
          <DialogContent sx={{ overflowX: 'hidden' }}>
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${video_id}?autoplay=1&loop=1&loop=1&start=${Math.floor(start_time)}&end=${Math.ceil(end_time)}`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </DialogContent>
        </Dialog>
      </Box>
      
    );
  };

export default VideoPopup