import { Box, Typography, Tabs, Tab } from '@mui/material'
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import FileUpload from '../../components/audioForm';
import BasicTabs from '../../components/tabs';
import { useNavigate } from 'react-router-dom';
import TitleDescription from '../../components/titleDescription';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={'span'} variant="body2">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const VideoDetails = () => {
    const { id } = useParams();
    const [isLoading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [update, setUpdate] = useState(0);
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
      navigate(`?maintab=${newValue}`);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/audio_files/${id}`,
        {
          withCredentials: true, // Include cookies in the request
        }
        )
        .then((response) => {
            setTableData(response.data);
            setLoading(false);
        })
        .catch((error) => {
            console.error(error);
        });
    }, [update]);

    
    useEffect(() => {
      // Get the selected tab from the URL query parameter (if available)
      const searchParams = new URLSearchParams(window.location.search);
      const tab = searchParams.get('maintab');
      if (tab !== null) {
        setValue(parseInt(tab, 10));
      }
    }, []);

    return (
        <Box
            margin="50px"
        >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Audio" {...a11yProps(0)} />
                    <Tab label="Title & Description" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <FileUpload update={update} setUpdate={setUpdate} id={id}/>
                <BasicTabs update={update} setUpdate={setUpdate} id={id}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <TitleDescription id={id}/>
            </CustomTabPanel>
        </Box>
    )
}

export default VideoDetails;